<template>
  <b-card no-body class="card-developer-meetup">
    <b-card-body>
      <b-media no-body class="mt-2">
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="HashIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.serviceCode") }}</h6>
          <small>{{ data.service_code }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="PackageIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.productName") }}</h6>
          <small>{{ data.prodcut_name }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="UserIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.client") }}</h6>
          <router-link
            :to="{ name: 'ViewClient', params: { id: data.client.id } }"
          >
            <small>{{ data.client.name }}</small>
          </router-link>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="PieChartIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.category") }}</h6>
          <small>{{ data.category.name }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="ToolIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.service") }}</h6>
          <small>{{ data.service.name }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="TagIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.sampleType") }}</h6>
          <small>{{ data.sample_type.name }}</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="BoxIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">{{ $t("g.status") }}</h6>
          <small>{{ data.stauts }}</small>
        </b-media-body>
      </b-media>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
