<template>
  <div>
    <b-card no-body class="card-developer-meetup">
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <h4>
              {{ $t("g.reviewAccepted") }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6 mt-2">
            <b-media no-body class="">
              <b-media-aside>
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="EyeIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.review") }}</h5>
                <h5 class="mb-0 t_1" v-if="data.review_action_date">
                  {{
                    data.is_reviewed ? $t("g.accepted") : $t("g.notAccepted")
                  }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="6 mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="EyeIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.is_review_accepted") }}</h5>
                <h5 class="mb-0 t_1" v-if="data.sent_for_tech_review_date">
                  {{
                    data.is_review_accepted
                      ? $t("g.accepted")
                      : $t("g.notAccepted")
                  }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="6 mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="EyeIcon" size="18" />
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <h5 class="mb-0">{{ $t("g.techReview") }}</h5>
                <h5 class="mb-0 t_1" v-if="data.tech_review_action_date">
                  {{
                    data.is_tech_review_accepted
                      ? $t("g.accepted")
                      : $t("g.notAccepted")
                  }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col md="6 mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="EyeIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.is_final_review_accepted") }}</h5>
                <h5 class="mb-0 t_1" v-if="data.final_review_action_date">
                  {{
                    data.is_final_review_accepted
                      ? $t("g.accepted")
                      : $t("g.notAccepted")
                  }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="6 mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.tech_evaluation") }}</h5>
                <h5 class="mb-0 t_1">
                  {{
                    data.tech_evaluation
                      ? formatDate(data.tech_evaluation.submit_date)
                      : ""
                  }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";
import dateTimeInFormat from "@/libs/format-date";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BMedia,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formatDate: dateTimeInFormat,
    };
  },
};
</script>

<style scoped>
.t_1 {
  margin-top: 0.4rem;
}
</style>
