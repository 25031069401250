<template>
  <div v-if="Object.keys(sub_Permissions).length">
    <b-card no-body class="card-developer-meetup">
      <b-card-body>
        <b-row class="mb-1">
          <b-col cols="12">
            <h4>
              {{ $t("g.action") }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3 mb-2" v-if="sub_Permissions.reviews">
            <b-link
              :to="{
                name: data.is_reviewed ? 'ReviewRequestsList' : 'RequestReview',
                params: { id: currentId },
              }"
              role="button"
              class="btn-link"
            >
              <span>{{
                data.is_reviewed ? $t("g.viewReview") : $t("g.addReview")
              }}</span>
            </b-link>
          </b-col>

          <!--======== Edit Tech Evaluations Permissions =========== -->

          <b-col md="3" v-if="sub_Permissions.techEvaluations">
            <b-button
              variant="primary"
              role="button"
              class="btn-link"
              :disabled="!data.is_review_accepted"
              @click="sendToTechEvaluation()"
            >
              <span>{{
                !data.is_sent_for_tech
                  ? $t("g.startTechEvaluation")
                  : $t("g.viewTechEvaluation")
              }}</span>
            </b-button>
          </b-col>
          <!--======== Edit Tech Evaluations Permissions =========== -->

          <b-col md="3" v-if="sub_Permissions.techEvaluationsReviews">
            <b-link
              :to="{
                name: data.tech_review_action_date
                  ? 'ListTechEvaluationsReviews'
                  : 'AddTechEvaluationsReviews',
                params: { id: currentId },
              }"
              role="button"
              class="btn-link"
              :disabled="!data.sent_for_tech_review_date"
            >
              <span>{{
                data.tech_review_action_date
                  ? $t("g.viewTechEvaluationsReviews")
                  : $t("g.addTechEvaluationsReviews")
              }}</span>
            </b-link>
          </b-col>

          <b-col md="3" v-if="sub_Permissions.finalReviews">
            <b-link
              :to="{
                name: data.final_review_action_date
                  ? 'ListFinalReviews'
                  : 'AddFinalReviews',
                params: { id: currentId },
              }"
              role="button"
              class="btn-link"
              :disabled="!data.is_tech_review_accepted"
            >
              <span>{{
                data.final_review_action_date
                  ? $t("g.viewFinalReviews")
                  : $t("g.addFinalReviews")
              }}</span>
            </b-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BRow, BCol, BLink, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BLink,
    BButton,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    currentId: {
      type: String,
      default: "",
    },
    sub_Permissions: Object,
  },
  methods: {
    sendToTechEvaluation() {
      if (this.data.sent_for_tech_review_date) {
        console.log("test1");
        this.$router.push({
          name: "ViewTechEvaluationReport",
          params: { id: this.currentId, tech_id: this.data.tech_evaluation.id },
        });
      } else if (
        this.data.is_sent_for_tech === false &&
        this.data.sent_for_tech_review_date === null
      ) {
        console.log("test2");
        this.$http
          .post(`admin/serviceRequests/${this.currentId}/startTechEvaluation`)
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data.tech_evaluation_id);
              this.$router.push({
                name: "ViewTechEvaluation",
                params: {
                  id: this.currentId,
                  tech_id: res.data.tech_evaluation_id
                },
              });
            }
          })
          .catch((err) => {
            for (const key in err.response.data.errors) {
              this.makeToast(
                "danger",
                err.response.data.errors[key][0],
                this.$t("g.send.errorTitle")
              );
            }
            console.log(err);
          });
      } else if (
        this.data.is_sent_for_tech === true &&
        this.data.sent_for_tech_review_date === null
      ) {
        console.log("test3");
        console.log(this.currentId, this.data.tech_evaluation.id);
        this.$router.push({
          name: "ViewTechEvaluation",
          params: {
            id: this.currentId,
            tech_id: this.data.tech_evaluation
              ? this.data.tech_evaluation.id
              : null,
          },
        });
      }
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7367f0;
  width: 140px;
  height: 40px;
  border-radius: 4px;
  text-align: center;

  &[aria-disabled="true"] {
    opacity: 0.5;
  }

  span {
    color: #fff;
    font-size: 0.8rem;
    font-weight: 900;
  }
}
</style>
