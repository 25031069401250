<template>
  <div>
    <b-card no-body class="card-developer-meetup">
      <b-card-body>
        <b-row class="mb-2">
          <b-col cols="12">
            <h4>
              {{ $t("g.documentsAndRegulations") }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <a
              target="_blank"
              rel="noopener"
              v-if="data.cert_request_doc"
              :href="data.cert_request_doc.path"
            >
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="FileIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0">{{ $t("g.cert") }}</h5>
                  <h5 class="mb-0">{{ data.cert_request_doc.description }}</h5>
                </b-media-body>
              </b-media>
            </a>
          </b-col>
          <b-col md="6">
            <a
              v-if="data.shipping_doc"
              :href="data.shipping_doc.path"
              target="_blank"
              rel="noopener"
            >
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="FileIcon" size="18" />
                  </b-avatar>
                </b-media-aside>

                <b-media-body>
                  <h5 class="mb-0">{{ $t("g.shipping") }}</h5>
                  <h5 class="mb-0">{{ data.shipping_doc.description }}</h5>
                </b-media-body>
              </b-media>
            </a>
          </b-col>
          <b-col md="6 mt-2">
            <a
              v-if="data.factory_doc"
              :href="data.factory_doc.path"
              rel="noopener"
            >
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="FileIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0">{{ $t("g.factory") }}</h5>
                  <h5 class="mb-0">{{ data.factory_doc.description }}</h5>
                </b-media-body>
              </b-media>
            </a>
          </b-col>
          <b-col md="6 mt-2">
            <a
              v-if="data.supplying_doc"
              :href="data.supplying_doc.path"
              target="_blank"
              rel="noopener"
            >
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="FileIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0">{{ $t("g.supplying") }}</h5>
                  <h5 class="mb-0">{{ data.supplying_doc.description }}</h5>
                </b-media-body>
              </b-media>
            </a>
          </b-col>
          <b-col md="6 mt-2">
            <a
              v-if="data.commercial_record_doc"
              :href="data.commercial_record_doc.path"
              target="_blank"
              rel="noopener"
            >
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="FileIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0">{{ $t("g.commercialRecord") }}</h5>
                  <h5 class="mb-0">
                    {{ data.commercial_record_doc.description }}
                  </h5>
                </b-media-body>
              </b-media>
            </a>
          </b-col>
          <b-col
            md="6 mt-2"
            v-for="(item, index) in data.attachments"
            :key="index"
          >
            <a
              v-if="item.path"
              :href="item.path"
              target="_blank"
              rel="noopener"
            >
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="FileIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0">{{ $t("g.attachments") }}</h5>
                  <h5 class="mb-0">{{ item ? item.description : '' }}</h5>
                </b-media-body>
              </b-media>
            </a>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BMedia,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
