<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.show" />

    <b-row v-else class="justify-content-md-center">
      <b-col md="9">
        <service-request-action
          :data="serviceRequest"
          :sub_Permissions="sub_Permissions"
          :currentId="currentId"
        />
        <date-informations :data="serviceRequest" />
        <review-accepted :data="serviceRequest" />
        <documentation :data="serviceRequest" />
      </b-col>
      <b-col md="3">
        <service-request-informations :data="serviceRequest" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Error from "@/views/components/logic/error.vue";
import Loading from "@/views/components/logic/loading.vue";
import serviceRequestInformations from "./serviceRequestInformations.vue";
import DateInformations from "./dateInformations.vue";
import Documentation from "./documentation.vue";
import ReviewAccepted from "./reviewAccepted.vue";
import ServiceRequestAction from "./serviceRequestAction.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    BRow,
    BCol,
    serviceRequestInformations,
    DateInformations,
    Documentation,
    ReviewAccepted,
    ServiceRequestAction,
    Loading,
    Error,
    ContentNotView,
  },
  data() {
    return {
      serviceRequest: null,
      isLoading: true,
      isError: false,
      currentId: "",
      subPermission: {},
      sub_Permissions: {},
    };
  },
  beforeMount() {
    this.currentId = this.$route.params.id;
    this.$http
      .get(`admin/serviceRequests/${this.$route.params.id}`)
      .then((res) => {
        this.serviceRequest = res.data.data;
        
        setTimeout(() => {
          this.isLoading = false;
          this.isError = false;
          store.dispatch("GET_PERMISSION", "serviceRequests");
          store.dispatch("GET_PERMISSIONS", [
            "techEvaluations",
            "reviews",
            "finalReviews",
            "techEvaluationsReviews",
          ]);
          this.subPermission = store.state.permissions.sub;
          this.sub_Permissions = store.state.permissions.sub_Permissions;
        }, 1000);
      })
      .catch((err) => {
        this.isLoading = false;
        this.isError = true;
        console.log(err);
      });
  },
};
</script>

<style></style>
