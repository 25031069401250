<template>
  <div>
    <b-card no-body class="card-developer-meetup">
      <b-card-body>
        <b-row class="mb-2">
          <b-col cols="12">
            <h4>
              {{ $t("g.theDates") }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-media no-body class="">
              <b-media-aside>
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="ArrowDownCircleIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.receiveDate") }}</h5>
                <h5 class="mb-0 t_1">{{ formatDate(data.receive_date) }}</h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="ArrowRightCircleIcon" size="18" />
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <h5 class="mb-0">{{ $t("g.closedDate") }}</h5>
                <h5 class="mb-0 t_1">{{ formatDate(data.closed_date) }}</h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.sentForTechDate") }}</h5>
                <h5 class="mb-0 t_1">
                  {{ formatDate(data.sent_for_tech_date) }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4 mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.sentForTechReviewDate") }}</h5>
                <h5 class="mb-0 t_1">
                  {{ formatDate(data.sent_for_tech_review_date) }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4  mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.techReviewActionDate") }}</h5>
                <h5 class="mb-0 t_1">
                  {{ formatDate(data.tech_review_action_date) }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4  mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.sentForFinalReviewDate") }}</h5>
                <h5 class="mb-0 t_1">
                  {{ formatDate(data.sent_for_final_review_date) }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4  mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.reviewActionDate") }}</h5>
                <h5 class="mb-0 t_1">
                  {{ formatDate(data.review_action_date) }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4  mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.finalReviewActionDate") }}</h5>
                <h5 class="mb-0 t_1">
                  {{ formatDate(data.final_review_action_date) }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="4  mt-2">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="35">
                  <feather-icon icon="CalendarIcon" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">{{ $t("g.excpectedReviewEndDate") }}</h5>
                <h5 class="mb-0 t_1">
                  {{ formatDate(data.excpected_review_end_date) }}
                </h5>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";

import dateTimeInFormat from "@/libs/format-date";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BMediaAside,
    BCardBody,
    BMediaBody,
    BMedia,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      formatDate: dateTimeInFormat,
    };
  },
};
</script>

<style scoped>
.t_1 {
  margin-top: 0.4rem;
}
</style>
