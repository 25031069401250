var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.sub_Permissions).length)?_c('div',[_c('b-card',{staticClass:"card-developer-meetup",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("g.action"))+" ")])])],1),_c('b-row',[(_vm.sub_Permissions.reviews)?_c('b-col',{attrs:{"md":"3 mb-2"}},[_c('b-link',{staticClass:"btn-link",attrs:{"to":{
              name: _vm.data.is_reviewed ? 'ReviewRequestsList' : 'RequestReview',
              params: { id: _vm.currentId },
            },"role":"button"}},[_c('span',[_vm._v(_vm._s(_vm.data.is_reviewed ? _vm.$t("g.viewReview") : _vm.$t("g.addReview")))])])],1):_vm._e(),(_vm.sub_Permissions.techEvaluations)?_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{staticClass:"btn-link",attrs:{"variant":"primary","role":"button","disabled":!_vm.data.is_review_accepted},on:{"click":function($event){return _vm.sendToTechEvaluation()}}},[_c('span',[_vm._v(_vm._s(!_vm.data.is_sent_for_tech ? _vm.$t("g.startTechEvaluation") : _vm.$t("g.viewTechEvaluation")))])])],1):_vm._e(),(_vm.sub_Permissions.techEvaluationsReviews)?_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{staticClass:"btn-link",attrs:{"to":{
              name: _vm.data.tech_review_action_date
                ? 'ListTechEvaluationsReviews'
                : 'AddTechEvaluationsReviews',
              params: { id: _vm.currentId },
            },"role":"button","disabled":!_vm.data.sent_for_tech_review_date}},[_c('span',[_vm._v(_vm._s(_vm.data.tech_review_action_date ? _vm.$t("g.viewTechEvaluationsReviews") : _vm.$t("g.addTechEvaluationsReviews")))])])],1):_vm._e(),(_vm.sub_Permissions.finalReviews)?_c('b-col',{attrs:{"md":"3"}},[_c('b-link',{staticClass:"btn-link",attrs:{"to":{
              name: _vm.data.final_review_action_date
                ? 'ListFinalReviews'
                : 'AddFinalReviews',
              params: { id: _vm.currentId },
            },"role":"button","disabled":!_vm.data.is_tech_review_accepted}},[_c('span',[_vm._v(_vm._s(_vm.data.final_review_action_date ? _vm.$t("g.viewFinalReviews") : _vm.$t("g.addFinalReviews")))])])],1):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }